//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Loader from '~/components/ReusableComponents/Loader.vue';
export default {
    name: 'NoNav',
    components: { Loader },
    computed: {
        ...mapGetters({
            fetchingUser: 'auth/fetchingUser'
        })
    }
};
